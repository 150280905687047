import React from "react";

export function PropWithNameAndValue({
  label,
  value,
  last = false,
}: {
  label: string;
  value: string;
  last?: boolean;
}) {
  return (
    <div
      className={`flex justify-between ${
        !last
          ? "border-b border-[#ddd] md:border-[#c2c2bf] pb-2 md:pb-1"
          : "md:border-b md:border-[#c2c2bf] md:pb-0"
      } pt-2 mt-1 md:px-2`}
    >
      <div className="font-medium text-base md:text-lg">{label}</div>
      <div className="text-sm md:text-lg">{value}</div>
    </div>
  );
}
