import anime from "animejs";
import React, { useMemo, useState, useRef, useEffect } from "react";

type Props = {
  title: string;
  value: number;
  colorFn: (value: number) => string;
};

export const FoodExplorerFoodModeScore = React.memo(ModeScore);

function ModeScore({ title, value, colorFn }: Props) {
  const color = useMemo(() => colorFn(value), [colorFn, value]);
  const [animValue, setAnimValue] = useState(0);
  const obj = useRef({ val: 0 });

  useEffect(() => {
    anime({
      targets: obj.current,
      round: true,
      val: value,
      duration: 1000 * (value / 100),
      easing: "easeOutQuad",
      update: () => setAnimValue(obj.current.val),
    });
  }, [obj, value]);

  return (
    <div className="flex flex-col">
      <h2 className="md:px-2 text-sm sm:text-base md:text-2xl">{title}</h2>
      <h3 className="md:px-2 text-2xl md:text-[2rem] my-1 tabular-nums" style={{ color }}>
        {value ?? "-"}
      </h3>
      <div
        className="h-1 rounded-full mb-2 md:mb-12 bg-[#ececec] md:bg-[#E5E1D9]"
        style={{
          backgroundImage: `linear-gradient(
              to right,
              ${color} 0%,
              ${color} ${animValue}%,
              transparent ${animValue}%,
              transparent 100%
            )`,
        }}
      ></div>
    </div>
  );
}
