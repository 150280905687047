import { useQuery } from "@apollo/client";
import React, { useCallback, useMemo } from "react";
import Button from "../../../components/Button/Button";
import { useLink, useNavigate } from "../../../components/Link/Link";
import { RecipePage, SearchPage } from "../../../pages";
import GetRecipesSearch from "../../search/api/GetRecipesSearch.graphql";
import { RecipeResult } from "../../search/components/RecipeResult";
import {
  SearchRecipesQuery_listRecipes,
  SearchRecipesQuery_listRecipes_recipes,
} from "../../../types/Search_GetRecipes";
import { sendEvent } from "../../../utils/gtm";
import { getRecipeTime } from "../../../utils/recipe";
import { useTranslations } from "../translations";

export function RelatedRecipes({ keyword }: { keyword: string }) {
  const link = useLink();
  const tt = useTranslations();
  const { loading, data } = useQuery<{ listRecipes: SearchRecipesQuery_listRecipes }>(GetRecipesSearch, {
    fetchPolicy: "network-only",
    nextFetchPolicy: "cache-first",
    variables: {
      page: 1,
      pageSize: 4,
      query: keyword,
      tagFilters: [],
      satietyScoreRange: { min: 0, max: 100 },
    },
  });

  const navigate = useNavigate();

  const handleClickOnRecipe = useCallback(
    (slug: string) => () => {
      link({ to: RecipePage, params: { slug } });
    },
    [link]
  );

  const handleShowMore = useCallback(() => {
    sendEvent({
      eventName: "food_navigator",
      action: "foodnavigator_ingredient_itempage_morerecipes",
      payload: {
        keyword,
      },
    });
    navigate({ to: SearchPage, query: { s: keyword } });
  }, [navigate, keyword]);

  const numOfRecipes = useMemo(() => (data && data?.listRecipes?.recipes.length) || 0, [data]);
  const hasRecipes = useMemo(() => numOfRecipes > 0, [data]);
  const hideShowMoreButton = useMemo(() => numOfRecipes < 4, [numOfRecipes]);

  if (hasRecipes) {
    return (
      <div>
        <h2 className="mb-4 md:mb-6 display-xs md:display-s">{tt.relatedRecipesHeading}</h2>
        <ul className={`list-none p-0 grid grid-rows-${numOfRecipes} md:grid-rows-none md:grid-cols-4 gap-4`}>
          {loading ? (
            <>
              <li className="bg-sand rounded-md aspect-[0.83]"></li>
              <li className="bg-sand rounded-md aspect-[0.83]"></li>
              <li className="bg-sand rounded-md aspect-[0.83]"></li>
            </>
          ) : (
            hasRecipes &&
            data?.listRecipes.recipes.map((recipe: SearchRecipesQuery_listRecipes_recipes | null) => {
              return recipe ? (
                <RecipeResult
                  recipe={recipe}
                  key={recipe.slug}
                  isAllowedToSeeRecipe={() => true}
                  isPremium={true}
                  loggedIn={true}
                  time={tt.quantities.timeInMinutes(getRecipeTime(recipe.time))}
                  onClickOnRecipe={handleClickOnRecipe(recipe.slug)}
                  link={link}
                />
              ) : null;
            })
          )}
        </ul>
        <div className="flex justify-center my-6 md:my-20">
          {!hideShowMoreButton && (
            <Button color="black" onClick={handleShowMore}>
              {tt.showMoreButton}
            </Button>
          )}
        </div>
      </div>
    );
  }

  return <div></div>;
}
