import { MdChevronRight } from "@react-icons/all-files/md/MdChevronRight";
import React, { useContext, useMemo } from "react";

import { Link } from "../../components/Link/Link";
import { pageContext } from "../../components/PageProvider/PageProvider";
import { FoodExplorerFoodPage as PageDefinition, FoodExplorerPage, GetPageContext } from "../../pages";
import { toCMSAssetUrl } from "../../utils/utils";
import { FoodExplorerFoodModeScore } from "./foodPage/FoodExplorerFoodModeScore";
import { FoodItemsSuggestions } from "./foodPage/FoodItemsSuggestions";
import { PremiumMembership } from "./foodPage/PremiumMembership";
import { PropWithNameAndValue } from "./foodPage/PropWithNameAndValue";
import { RelatedRecipes } from "./foodPage/RelatedRecipes";
import { FoodExplorerMode } from "./hooks/useFoodExplorerModes";
import { useTranslations } from "./translations";
import { colorByCarbs, colorByProtein, colorBySatietyScore } from "./utils";

type FoodExplorerFoodPageContext = GetPageContext<typeof PageDefinition>;

type FoodItemLocationType = {
  state: {
    mode?: FoodExplorerMode;
  };
};

const FoodExplorerFoodPage = ({ location }: { location: FoodItemLocationType }) => {
  const {
    extraContext: { item },
  } = useContext<FoodExplorerFoodPageContext>(pageContext);

  const prevMode = location.state?.mode;

  const tt = useTranslations();

  const image = useMemo(() => toCMSAssetUrl(item.image.id, 350, 350), [item]);
  const title = useMemo(() => item.translations[0]?.title, [item]);
  const searchText = useMemo(() => item.translations[0]?.searchText, [item]);
  const description = useMemo(() => item.translations[0]?.description, [item]);

  const relatedRecipesTag = useMemo(
    () =>
      item.tags?.flatMap((value) => ({
        slug: value.tag.translations[0].tagSlug,
        label: value.tag.translations[0].tagTitle,
      }))[0] || {},
    [item]
  );

  return (
    <div className="w-full flex flex-col">
      <div className="w-full bg-sand p-6 lg:py-8 lg:pb-28">
        <div className="w-full max-w-[1200px] mx-auto">
          <div className="flex items-center gap-2 text-sm font-medium w-full">
            <Link to={FoodExplorerPage} params={{ mode: prevMode ?? FoodExplorerMode.CARBS }}>
              {tt.breadcrumbLinks.foodNavigator}
            </Link>
            <MdChevronRight />
            <Link
              to={FoodExplorerPage}
              params={{
                mode: prevMode ?? FoodExplorerMode.CARBS,
                tag: relatedRecipesTag.slug,
              }}
            >
              {relatedRecipesTag.label}
            </Link>
          </div>
          <div className="flex flex-col lg:gap-3 lg:flex-row justify-between lg:mt-10">
            <div className="flex w-full lg:w-4/12 justify-center">
              <div className="px-14 pt-8 pb-6 lg:px-0 lg:py-0">
                <div className="aspect-[1/1] w-60 lg:w-[350px] flex justify-center items-center overflow-hidden rounded-full bg-white p-8">
                  <img src={image} alt={title} className="w-full" />
                </div>
              </div>
            </div>
            <div className="w-full lg:w-8/12">
              <h1 className="lg:mt-8 w-full text-center lg:text-left text-2xl lg:text-[3.2rem] leading-none">
                {title}
              </h1>
              {description && <p className="mt-4">{description}</p>}
              <div className="flex flex-col mt-6 lg:mt-8 bg-white rounded-lg lg:bg-transparent lg:rounded-none p-4 lg:p-0">
                <div className="border-b border-[#ddd] uppercase font-medium text-[0.9rem] pb-2">
                  {tt.servingSizeValue(item.serving)}
                </div>
                <div className="grid grid-cols-3 gap-x-4 lg:gap-x-8 my-2 lg:mt-5 lg:mb-0">
                  <FoodExplorerFoodModeScore
                    title={tt.foodModeScore.satietyScore}
                    value={item.satietyScore}
                    colorFn={colorBySatietyScore}
                  />
                  <FoodExplorerFoodModeScore
                    title={tt.foodModeScore.netCarbs}
                    value={Math.round(item.netCarbs)}
                    colorFn={colorByCarbs}
                  />
                  <FoodExplorerFoodModeScore
                    title={tt.foodModeScore.protienPercentage}
                    value={Math.round(item.proteinPercent)}
                    colorFn={colorByProtein}
                  />
                </div>
                <div className="grid grid-cols-1 lg:grid-cols-3 gap-x-8">
                  <PropWithNameAndValue label={tt.nutrients.totalCarbs} value={`${item.carbs} g`} />
                  <PropWithNameAndValue label={tt.nutrients.fiber} value={`${item.fiber} g`} />
                  <PropWithNameAndValue label={tt.nutrients.sugar} value={`${item.sugar} g`} />
                  <PropWithNameAndValue label={tt.nutrients.protein} value={`${item.protein} g`} />
                  <PropWithNameAndValue label={tt.nutrients.fat} value={`${item.fat} g`} />
                  <PropWithNameAndValue
                    label={tt.nutrients.calories}
                    value={item.calories?.toString() ?? ""}
                    last={true}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="w-full max-w-[1200px] mx-auto p-6 lg:py-12 xl:px-0">
        <FoodItemsSuggestions suggestions={item.suggestions} />
        <RelatedRecipes keyword={searchText ?? title} />
        <PremiumMembership />
      </div>
    </div>
  );
};

export default FoodExplorerFoodPage;
