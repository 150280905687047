import React from "react";
import { toCMSAssetUrl } from "../../../utils/utils";
import { Link } from "../../../components/Link/Link";
import { FoodExplorerFoodPage } from "../../../pages";
import { FoodNavigatorSuggestions } from "../types";

export function FoodItemsSuggestions({ suggestions }: { suggestions: FoodNavigatorSuggestions[] }) {
  const modifiedData = suggestions.map((suggestion) => suggestion.relatedFoodItem);
  const hasSuggestions = suggestions.length > 0;

  return hasSuggestions ? (
    <div className="mb-16">
      <h2 className="mb-4 md:mb-6 display-xs md:display-s">Other suggestions</h2>
      <div className="grid grid-cols-2 sm:grid-cols-4 gap-4">
        {modifiedData.map((suggestion, idx) => (
          <div key={idx} className="border border-solid border-lighter-grey rounded-md px-4 md:px-8 py-2">
            <Link
              to={FoodExplorerFoodPage}
              params={{ slug: suggestion.translations[0]?.slug }}
              className="flex flex-col items-center"
            >
              <img
                className="w-full"
                src={toCMSAssetUrl(suggestion.image.id, 180, 180, "inside")}
                alt={suggestion.translations[0]?.title}
              />
              <span className="font-medium text-dark-grey leading-5 text-center gap-y-2">
                {suggestion.translations[0]?.title}
              </span>
            </Link>
          </div>
        ))}
      </div>
    </div>
  ) : null;
}
